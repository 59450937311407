import { forEach, mapKeys } from '@tunein/web-utils';
import reloadPage from 'src/client/utils/reloadPage';
import { cookieCategoriesMap } from '../constants/ads/consentCookieCategories';
import feature from '../constants/analytics/categoryActionLabel/feature';
import {
  activeConsentCategoriesCookie,
  ccpaOptOutCookie,
  globalOptOutCookie,
} from '../constants/cookies';
import {
  removeCookie,
  removeInactiveConsentCategoryCookies,
  setCookie,
} from '../utils/cookie';
import isServer from '../utils/isServer';
import {
  isUserOptedOutUnderCcpa,
  isUserOptedOutUnderGlobal,
} from './checkUserOptOutStatus';
import {
  events,
  CMP_API_VERSION,
  ONETRUST_SOURCE,
  US_PRIVACY_OPT_IN_STRING,
  US_PRIVACY_OPT_OUT_STRING,
  US_PRIVACY_SPEC_VERSION,
  activeCookieCategoryHandlers,
  cmpApiCommands,
  cmpStatus,
  oneTrustMethods,
  purposes,
  vendorConsentHandlers,
} from './constants/oneTrust';
import './onetrust-overrides.scss';

const ONETRUST_APIS_TIMEOUT = 5000;
const ONETRUST_US_PRIVACY_STRING_GENERATION_TIMEOUT = 2000;
const ONETRUST_INTERVALS_DELAY = 100;

/**
 * Manages user GDPR consent via the APIs provided by the OneTrust library.
 * CMP API specification: https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md
 *
 * @param {boolean} isMobileBlockerFlow - When true, closes GDPR banner until mobile blocker
 *  is closed. See AppConnector.js.
 * @param {boolean} openDialogOnInit - Set to true by the mobile blocker flow via
 *  openOneTrustBannerOnInit()
 * @param {string} dataSubjectId - The user ID or serial to associate with the OneTrust user profile
 * @param {object} actions
 *  @property {function} setOneTrustData - Primary action creator to set OneTrust consent data in
 *  @property {function} openOneTrustBannerOnInit - Action creator that signals dialog to
 *  open after mobile blocker is closed.
 *  @property {function} logClientError - Action creator that logs errors.
 *  @property {function} logClientInfo - Action creator that logs info.
 *  @property {function} logCategoryActionLabel
 */
class OneTrust {
  #options;

  #gdprApplies;

  #skipReportOnceOnOpenDialogOnInit = true;

  constructor() {
    if (isServer()) {
      return;
    }

    this.eventListenerIds = {};
    this.definitions = {
      interface: false,
      tcfApi: false,
      uspApi: false,
    };
  }

  get #oneTrust() {
    // eslint-disable-next-line
    return window.OneTrust;
  }

  get #tcfApi() {
    // eslint-disable-next-line no-underscore-dangle
    return window.__tcfapi;
  }

  get #usPrivacyApi() {
    // eslint-disable-next-line no-underscore-dangle
    return window.__uspapi;
  }

  /**
   * Consented-to cookie categories, as defined by OneTrust and configurable in the OneTrust dashboard.
   *
   * OnetrustActiveGroups: https://my.onetrust.com/s/article/UUID-66bcaaf1-c7ca-5f32-6760-c75a1337c226
   *
   * @returns {string}
   */
  get #activeCookieCategories() {
    // important: the lowercase `trust` is not a typo
    return window.OnetrustActiveGroups || '';
  }

  /**
   * Determines GDPR applicability and user consent.
   */
  async #initGdpr() {
    const isTcfApiAvailable = await this.#checkTcfApiAvailability();

    if (!isTcfApiAvailable) {
      this.#optUserIntoGdpr();
      return;
    }

    if (this.#options.isMobileBlockerFlow) {
      // Blocks the banner from showing and blocking the mobile blocker dialog and flipping a flag in the store to
      // later open the preference center, once the mobile blocker is closed.
      this.#addEventListener(events.bannerShown, (tcData) => {
        // we need to close the banner first, or else it'll show
        this.#invokeOneTrustMethod(oneTrustMethods.closeAll);
        this.#removeEventListener(events.bannerShown, tcData.listenerId);
        this.#options.actions.openOneTrustBannerOnInit();
      });
    } else {
      this.#determineGdprConsent();
    }
  }

  async #initCcpa() {
    const [gdprApplies] = await Promise.all([
      this.checkGdprApplicability(),
      this.#waitForUsPrivacyApi(),
    ]);

    if (gdprApplies) {
      this.#options.actions.setOneTrustData({ ccpa: { ccpaApplies: false } });
      return;
    }

    this.#getUsPrivacyData();
  }

  /**
   * Requests USP Data from OneTrust's USP API (window.__uspapi)
   */
  #getUsPrivacyData() {
    this.#usPrivacyApi?.(
      'getUSPData',
      US_PRIVACY_SPEC_VERSION,
      this.#handleUsPrivacyData,
    );
  }

  /**
   * Cyclically checks for the availability of the OnetrustActiveGroups
   */
  #waitForOneTrustActiveGroups() {
    if (this.#activeCookieCategories) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        const isTimeoutReached =
          Date.now() - this.startTime > ONETRUST_APIS_TIMEOUT;
        if (this.#activeCookieCategories || isTimeoutReached) {
          clearInterval(intervalId);
          resolve();
        }
      }, 10);
    });
  }

  /**
   * Cyclically checks for the availability of the OneTrust interface
   */
  #waitForOneTrust() {
    if (this.#oneTrust) {
      this.#logInterfaceDefinition();
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      const isTimeoutReached =
        Date.now() - this.startTime > ONETRUST_APIS_TIMEOUT;

      if (isTimeoutReached) {
        resolve();
        return;
      }

      if (!this.#oneTrust) {
        setTimeout(
          () => this.#waitForOneTrust().then(resolve),
          ONETRUST_INTERVALS_DELAY,
        );
        return;
      }

      this.#logInterfaceDefinition();
      resolve();
    });
  }

  /**
   * Cyclically checks for the availability of the USP API
   */
  #waitForUsPrivacyApi() {
    if (this.#usPrivacyApi) {
      this.#logUsPrivacyApiDefinition();
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      const isTimeoutReached =
        Date.now() - this.startTime > ONETRUST_APIS_TIMEOUT;

      if (isTimeoutReached) {
        resolve();
        return;
      }

      if (!this.#usPrivacyApi) {
        setTimeout(
          () => this.#waitForUsPrivacyApi().then(resolve),
          ONETRUST_INTERVALS_DELAY,
        );
        return;
      }

      this.#logUsPrivacyApiDefinition();
      resolve();
    });
  }

  /**
   * Cyclically checks for the availability of the TCF API, then determines GDPR applicability
   * via the provided geolocation method.
   *
   * OneTrust does not provide an API for communicating to us that a user is in a GDPR zone, so
   * we are leveraging the availability of the TCF API on the window to determine this.
   */
  #checkTcfApiAvailability() {
    if (this.#tcfApi) {
      this.#logTcfApiDefinition();
      return Promise.resolve(true);
    }

    return new Promise((resolve) => {
      if (Date.now() - this.startTime > ONETRUST_APIS_TIMEOUT) {
        resolve(false);
        return;
      }

      if (this.#tcfApi) {
        this.#logTcfApiDefinition();
        resolve(true);
        return;
      }

      setTimeout(
        () => this.#checkTcfApiAvailability().then(resolve),
        ONETRUST_INTERVALS_DELAY,
      );
    });
  }

  /**
   *  we need it to migrate old opt out to OneTrust Preference Center
   *  this migration is related only to CCPA Opt Out and Global Opt Out
   *  this logic can be removed in 400 day from OneTrust migration release date
   *  because Chrome supports up to 400 days cookies Max-Age, but need to check other browsers
   */
  async #migrateOldOptOutToOneTrust() {
    let withOldOptOut = false;

    if (isUserOptedOutUnderCcpa()) {
      withOldOptOut = true;
      removeCookie(ccpaOptOutCookie.name);
    } else if (isUserOptedOutUnderGlobal()) {
      withOldOptOut = true;
      removeCookie(globalOptOutCookie.name);
    }

    if (withOldOptOut) {
      await this.#rejectAll();
      this.#options.actions.setOneTrustData({ isOptedOutOfTargeting: true });
    }
  }

  /**
   * We set isOptedOutOfTargeting as a part of OneTrust data in the store
   */
  #setIsOptedOutOfTargeting = async () => {
    const isOptedOutOfTargeting = await this.checkIsOptedOutOfTargeting();
    this.#options.actions.setOneTrustData({ isOptedOutOfTargeting });
  };

  /**
   * Sets/removes cookies based on consent and updates redux store with consent preferences
   *
   * @param {object} tcData
   * @param {boolean} hasConsentBeenCaptured
   */
  #setConsentData(tcData = {}, hasConsentBeenCaptured = true) {
    const { actions } = this.#options;
    const consentedPurposes = mapKeys(
      tcData.purpose?.consents,
      (v, key) => purposes[key],
    );

    setCookie(
      activeConsentCategoriesCookie.name,
      null,
      this.#activeCookieCategories,
    );
    removeInactiveConsentCategoryCookies();

    if (hasConsentBeenCaptured) {
      this.#updateManualConsentVendors(tcData);
      this.#triggerActiveCookieCategoryHandlers();
    }

    this.#gdprApplies = tcData.gdprApplies;

    actions.setOneTrustData({
      shouldDialogOpen: false,
      gdpr: {
        tcString: tcData.tcString,
        gdprApplies: tcData.gdprApplies,
        consentedPurposes,
        isGdprReady: hasConsentBeenCaptured,
      },
    });
  }

  /**
   * For vendors scripts/libraries that:
   *
   * 1. require consent signals
   * 2. do not implement TCF V2
   * 3. use cookies that are not governed by the Global Vendor List
   *
   * We should register a handler function for that vendor cookie in the appropriate cookie category namespace (defined by activeCookieCategoryHandlers)
   * that will appropriately communicate user consent. This method iterates over the cookie categories and signals user opt-in for each method
   * registered for the cookie category, if that cookie category is considered "active" (i.e., consented-to) by OneTrust.
   *
   * OnetrustActiveGroups: https://my.onetrust.com/s/article/UUID-66bcaaf1-c7ca-5f32-6760-c75a1337c226
   */
  #triggerActiveCookieCategoryHandlers() {
    forEach(activeCookieCategoryHandlers, (handlers, cookieGroup) => {
      if (this.#activeCookieCategories.includes(cookieGroup)) {
        handlers.forEach((handler) => handler(true));
      }
    });
  }

  /**
   * For vendors scripts/libraries that:
   *
   * 1. require consent signals
   * 2. do not implement TCF V2
   * 3. implement behavior that is governed by the Global Vendor List
   *
   * We should register a handler function for that vendor that will appropriately communicate user
   * consent. This method iterates over these handlers and attempts to pass along the appropriate
   * user consent signal for that vendor.
   *
   * TCF V2 Global Vendor List: https://vendor-list.consensu.org/v2/vendor-list.json
   *
   * @param {object} tcData
   */
  #updateManualConsentVendors(tcData) {
    const vendorConsents = tcData?.vendor?.consents || {};

    forEach(vendorConsentHandlers, (vendorConsentHandler, vendorId) => {
      const vendorConsent = vendorConsents[vendorId] || false;

      try {
        // handlers should be defined with a single, opt-out-oriented Boolean parameter
        vendorConsentHandler(!vendorConsent);
      } catch (e) {
        this.#handleOneTrustError(e);
      }
    });
  }

  /**
   * Invokes a method available on the OneTrust API
   *
   * @param {string} method - OneTrust API method name
   * @param {...*} args - arguments to be passed into the method
   * @returns {*} - return value of the method call
   */
  // eslint-disable-next-line consistent-return
  async #invokeOneTrustMethod(method, ...args) {
    try {
      await this.#waitForOneTrust();
      return this.#oneTrust[method](...args);
    } catch (e) {
      e.context = { method };
      this.#handleOneTrustError(e);
    }
  }

  #logTcfApiDefinition() {
    if (!this.definitions.tcfApi) {
      this.definitions.tcfApi = true;

      const time = Date.now() - this.startTime;

      this.#options.actions.logClientInfo({
        message: 'ONE_TRUST.TCF_API.BECAME_DEFINED_IN',
        context: { time },
      });
    }
  }

  #logUsPrivacyApiDefinition() {
    if (!this.definitions.uspApi) {
      this.definitions.uspApi = true;

      const time = Date.now() - this.startTime;

      this.#options.actions.logClientInfo({
        message: 'ONE_TRUST.USP_API.BECAME_DEFINED_IN',
        context: { time },
      });
    }
  }

  #logInterfaceDefinition() {
    if (!this.definitions.interface) {
      this.definitions.interface = true;

      const time = Date.now() - this.startTime;

      this.#options.actions.logClientInfo({
        message: 'ONE_TRUST.INTERFACE.BECAME_DEFINED_IN',
        context: { time },
      });
    }
  }

  #reportAndReloadPage = async (tcData) => {
    // openDialogOnInit is true when calling init() after the mobile blocker is closed. The openDialogOnInit flag
    // triggers a call to the OneTrust method openSettingsDialog (aka `ToggleInfoDisplay`), which surfaces the
    // preference center. However, when we add an event listener on init() for the consentUpdated event
    // (aka `useractioncomplete`), the callback (this method) is immediately invoked with the wrong eventStatus
    // `useractioncomplete` (we believe this to be a OneTrust bug) and not the `cmpuishown` eventStatus. Because of this
    // bug, we need to implement this mini-hack to ignore the *first* event when openDialogOnInit is true.
    if (
      this.#options.openDialogOnInit &&
      this.#skipReportOnceOnOpenDialogOnInit
    ) {
      this.#skipReportOnceOnOpenDialogOnInit = false;
      return;
    }

    await this.#options.actions.logCategoryActionLabel({
      category: feature.category,
      action: feature.actions.gdpr,
      label: tcData.tcString,
    });

    reloadPage();
  };

  /**
   * Enables consent for all cookie categories (allowed for current region/country),
   * only necessary cookie categories are remained
   */
  #rejectAll() {
    this.#invokeOneTrustMethod(oneTrustMethods.rejectAll);
  }

  /**
   * Determines GDPR user consent via event listeners registered with the API's event system. Additionally, this method
   * sets the user's subject ID and optionally opens the preference settings dialog, as determined via the mobile
   * blocker flow.
   */
  #determineGdprConsent() {
    if (this.#options.openDialogOnInit) {
      this.#invokeOneTrustMethod(oneTrustMethods.openSettingsDialog);
    }

    this.#invokeOneTrustMethod(
      oneTrustMethods.setDataSubjectId,
      this.#options.dataSubjectId,
    );

    // the tcLoaded event is emitted on page load as soon as "Transparency & Consent" (GDPR) data
    // is made ready by the TCF API. See `events` definition for more information.
    this.#addEventListener(events.tcLoaded, this.#handleTcData);

    // the consentUpdated event is emitted whenever a user makes a change to their consent
    // preferences. The consent data is reported synchronously before reloading the page.
    // See `events` definition for more information.
    this.#addEventListener(events.consentUpdated, this.#reportAndReloadPage);

    // The bannerShown event is the only event first-time visitors will trigger on initial page
    // load, so we're registering this listener to make sure we diligently initialize consent for
    // those users. Listener is removed in #handleTcData().
    this.#addEventListener(events.bannerShown, (tcData) =>
      this.#handleTcData(tcData, false),
    );
  }

  /**
   * Sets opt-in consent values for GDPR in the redux store.
   */
  #optUserIntoGdpr() {
    this.#options.actions.setOneTrustData({
      gdpr: {
        gdprApplies: false,
        isGdprReady: true,
      },
    });
  }

  /**
   * Manually sets us_privacy signal in the redux store.
   */
  #manuallyApplyUsPrivacyString(isOptedOutOfTargeting) {
    this.#options.actions.setOneTrustData({
      isOptedOutOfTargeting,
      ccpa: {
        ccpaApplies: false,
        usPrivacyString: isOptedOutOfTargeting
          ? US_PRIVACY_OPT_OUT_STRING
          : US_PRIVACY_OPT_IN_STRING,
      },
    });
  }

  /**
   * Delegate function that handles command calls to the CMP API (window.__tcfapi)
   * with an internal callback that resolves the response/error with a passed-in callback
   *
   * @param {string} command - the name of the command per the CMP API specification
   * @param {string|null} eventName - the name of the event to register, if applicable
   * @param {function} callback - the callback function for the event listener
   * @param {*} [optionalParameter] - optional parameter uniquely defined per command option in the
   *  CMP API specification
   * @param {function} [apiCallbackOverride] - optional override for the internally-defined
   *  apiCallback function
   */
  #tcfApiDelegate(
    command,
    eventName,
    callback,
    optionalParameter,
    apiCallbackOverride,
  ) {
    // this flexibility allows us to handle "bubbling up" errors for nested async operations.
    let responseHandlers;
    if (typeof callback === 'function') {
      responseHandlers = {
        resolve: callback,
        reject: (e) => {
          throw e;
        },
      };
    } else {
      responseHandlers = callback;
    }

    const apiCallback = (response, success) => {
      if (!success) {
        const error =
          response instanceof Error
            ? response
            : new Error(
                typeof response === 'string'
                  ? response
                  : JSON.stringify(response),
              );

        error.context = { command, eventName, cmpApiVersion: CMP_API_VERSION };
        responseHandlers.reject(error);
      }

      if (eventName) {
        this.eventListenerIds[eventName] = response.listenerId;
      }

      const isMatchedEventStatus = response.eventStatus === eventName;
      const isGdprNotApplicable =
        eventName === events.tcLoaded &&
        response.cmpStatus === cmpStatus.loaded &&
        response.gdprApplies === false;

      if (!eventName || isMatchedEventStatus || isGdprNotApplicable) {
        responseHandlers.resolve(response);
      }
    };

    this.#tcfApi(
      command,
      CMP_API_VERSION,
      apiCallbackOverride || apiCallback,
      optionalParameter,
    );
  }

  /**
   * A helper method to invoke the #addEventListener command on the CMP API
   *
   * @param {string} eventName - the event name as defined by the CMP API specification
   * @param {function} callback - a callback to handle the response
   */
  #addEventListener(eventName, callback) {
    this.#tcfApiDelegate(cmpApiCommands.addEventListener, eventName, callback);
  }

  /**
   * A helper method to invoke the #removeEventListener command on the CMP API
   *
   * @param {string} eventName - the event name as defined by the CMP API specification
   * @param {number} listenerId - the listener ID provided by the response to the #addEventListener
   *  command
   */
  #removeEventListener(eventName, listenerId) {
    const apiCallbackOverride = (success) => {
      if (!success) {
        this.#handleRemoveEventListenerError(eventName);
      }
    };

    this.#tcfApiDelegate(
      cmpApiCommands.removeEventListener,
      eventName,
      null,
      listenerId,
      apiCallbackOverride,
    );
  }

  /**
   * A callback for commands called on the CMP API.
   *
   * @param {object} tcData - the TC Data object as defined by the CMP API specification
   * @param {boolean} hasConsentBeenCaptured - indicates whether or not the call was user-initiated
   */
  #handleTcData = (tcData, hasConsentBeenCaptured = true) => {
    // The bannerShown event is the only event first-time visitors will trigger on initial page
    // load. Removing the listener for this event ensures we don't update consent on user-initiated
    // banner requests. We're still listening for selection changes to capture consent updates.
    const bannerShownListenerId = this.eventListenerIds[events.bannerShown];
    if (bannerShownListenerId) {
      delete this.eventListenerIds[events.bannerShown];
      this.#removeEventListener(events.bannerShown, bannerShownListenerId);
    }

    this.#invokeOneTrustMethod(
      oneTrustMethods.setDataSubjectId,
      this.#options.dataSubjectId,
    );
    // The timeout is required to give OneTrust enough time to set the #activeCookieCategories cookie.
    setTimeout(() => this.#setConsentData(tcData, hasConsentBeenCaptured), 0);
  };

  /**
   * A callback for commands called on the USP API.
   *
   * @param {object} usPrivacyData - the US Privacy Data object as defined by the USP API specification
   */
  #handleUsPrivacyData = (usPrivacyData) => {
    const usPrivacyString = usPrivacyData?.uspString || '';
    this.usPrivacyStringGenerationStartTime =
      this.usPrivacyStringGenerationStartTime || Date.now();
    const time = Date.now() - this.usPrivacyStringGenerationStartTime;
    const isTimeoutReached =
      time > ONETRUST_US_PRIVACY_STRING_GENERATION_TIMEOUT;

    if (usPrivacyData?.version && !usPrivacyString && !isTimeoutReached) {
      setTimeout(() => this.#getUsPrivacyData(), ONETRUST_INTERVALS_DELAY);
      return;
    }

    this.#options.actions.logClientInfo({
      message: 'ONE_TRUST.US_PRIVACY_STRING.BECAME_DEFINED_IN',
      context: {
        time: Date.now() - this.startTime,
        relativeTime: time,
        defined: !!usPrivacyString.length,
      },
    });

    // uspString consists from 4 characters: 1 -- version, 2 -- Y/N, 3 -- Y/N, 4 -- Y/N
    // that's why we assume that if it includes "-" so data is not full and valid
    // for all countries and region except California the uspApi provides uspString equal to "1---"
    const ccpaApplies =
      !!usPrivacyString.length && !usPrivacyString.includes('-');

    this.#options.actions.setOneTrustData({
      ccpa: {
        ccpaApplies,
        usPrivacyString: ccpaApplies ? usPrivacyString : '',
      },
    });
  };

  /**
   * Generic error handler for the methods in this class.
   * Logs errors via logClientError() action creator.
   *
   * @param {error} error
   */
  #handleOneTrustError(error = {}) {
    this.#options.actions.logClientError({
      message: ONETRUST_SOURCE,
      context: {
        ...error?.context,
        error,
      },
    });
  }

  /**
   * Error handler for #removeEventListener command.
   *
   * @param {string} eventName
   */
  #handleRemoveEventListenerError(eventName) {
    this.#handleOneTrustError(
      new Error(`Error removing event listener for event: ${eventName}`),
    );
  }

  init(options) {
    this.#options = options;
    this.startTime = Date.now();

    if (options.isDiscord) {
      this.#optUserIntoGdpr();
      this.#manuallyApplyUsPrivacyString(true);
      return;
    }

    this.#migrateOldOptOutToOneTrust();
    this.#setIsOptedOutOfTargeting();
    this.#initGdpr();
    this.#initCcpa();
    this.#invokeOneTrustMethod(
      oneTrustMethods.onConsentChanged,
      this.#setIsOptedOutOfTargeting,
    );
  }

  /**
   * This method is used only for analytics
   */
  async checkIsOptedOutOfPerformance() {
    await this.#waitForOneTrustActiveGroups();
    const activeCookieCategories = this.#activeCookieCategories.split(',');
    return !activeCookieCategories.includes(cookieCategoriesMap.performance);
  }

  /**
   * Cyclically checks for the GDPR applicability
   */
  checkGdprApplicability() {
    if (typeof this.#gdprApplies === 'boolean') {
      return Promise.resolve(this.#gdprApplies);
    }

    return new Promise((resolve) => {
      const isTimeoutReached =
        Date.now() - this.startTime > ONETRUST_APIS_TIMEOUT;

      if (isTimeoutReached) {
        this.#gdprApplies = false;
        resolve(this.#gdprApplies);
        return;
      }

      if (typeof this.#gdprApplies === 'boolean') {
        resolve(this.#gdprApplies);
        return;
      }

      setTimeout(
        () => this.checkGdprApplicability().then(resolve),
        ONETRUST_INTERVALS_DELAY,
      );
    });
  }

  /**
   * According to the OneTrust integration doc, if a user is opted out, the targeting cookie is disabled.
   */
  async checkIsOptedOutOfTargeting() {
    await this.#waitForOneTrustActiveGroups();
    const activeCookieCategories = this.#activeCookieCategories.split(',');
    return !activeCookieCategories.includes(cookieCategoriesMap.targeting);
  }

  openSettingsDialog() {
    this.#invokeOneTrustMethod(oneTrustMethods.openSettingsDialog);
  }

  setDataSubjectId(dataSubjectId) {
    this.#invokeOneTrustMethod(oneTrustMethods.setDataSubjectId, dataSubjectId);
  }

  /**
   * Sets a class on the body which is used as a CSS selector for applying styles to the floating button.
   * The CSS styles live in the OneTrust dashboard here: https://app.onetrust.com/cookies/templates
   * The floating button HTML is asynchronously loaded and injected into the DOM by the OneTrust library, so we're
   * relying on class names on the body to avoid having to continuously spy on the DOM to discover the elements.
   *
   * @param {bool} showFloatingGdprButton
   */
  handleFloatingButtonChange(showFloatingGdprButton) {
    const bodyEl = document.querySelector('body');

    if (showFloatingGdprButton) {
      bodyEl.classList.add('floatingGdprButtonVisible');
    } else {
      bodyEl.classList.remove('floatingGdprButtonVisible');
    }
  }

  /**
   * Sets a class on the body which is used as a CSS selector for applying styles to the floating button.
   * The CSS styles live in the OneTrust dashboard here: https://app.onetrust.com/cookies/templates
   * The floating button HTML is asynchronously loaded and injected into the DOM by the OneTrust library, so we're
   * relying on class names on the body to avoid having to continuously spy on the DOM to discover the elements.
   *
   * @param {bool} isPlayerVisible
   */
  handlePlayerVisibilityChange(isPlayerVisible) {
    const bodyEl = document.querySelector('body');

    if (isPlayerVisible) {
      bodyEl.classList.add('playerVisible');
    } else {
      bodyEl.classList.remove('playerVisible');
    }
  }

  /**
   When we are calling oneTrust.ToggleInfoDisplay() --> the floating button became turned but without the cross button
   inside. Looks like a OneTrust issue. For that reason we update this button programmatically to enable the Preference
   Center closing by clicking on it.
   */
  flipFloatingButtonOnInfoDisplay() {
    const settingsFloatingButton = document.querySelector(
      '#ot-sdk-btn-floating',
    );
    if (settingsFloatingButton) {
      const buttonFront = document.querySelector(
        '#ot-sdk-btn-floating > div.ot-floating-button__front.custom-persistent-icon > button',
      );
      const buttonBack = document.querySelector(
        '#ot-sdk-btn-floating > div.ot-floating-button__back.custom-persistent-icon > button',
      );

      buttonFront.style.display = 'none';
      buttonFront.setAttribute('aria-hidden', 'true');

      buttonBack.style.display = 'block';
      buttonBack.setAttribute('aria-hidden', 'false');
    }
  }
}

export default new OneTrust();
