import { comscoreBeacon } from 'src/client/analytics/comscore/comscoreUtils';
import { grantFacebookPixelConsent } from '../../../client/analytics/facebook/fbTrack';
import { processGTagEvents } from '../../../client/analytics/vendorScripts/loadGTag';
import { cookieCategoriesMap } from '../../constants/ads/consentCookieCategories';
import keyMirror from '../../utils/keyMirror';

export const ONETRUST_SOURCE = 'onetrust';

// https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md#cmp-api-v20
export const CMP_API_VERSION = 2;
export const US_PRIVACY_SPEC_VERSION = 1;

// https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md#what-required-api-commands-must-a-cmp-support
export const cmpApiCommands = keyMirror(
  'ping',
  'addEventListener',
  'removeEventListener',
);

// https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md#addeventlistener
export const events = {
  tcLoaded: 'tcloaded',
  bannerShown: 'cmpuishown',
  consentUpdated: 'useractioncomplete',
};

// https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md#ping-status-codes
export const cmpStatus = {
  loaded: 'loaded',
};

// https://my.onetrust.com/s/article/UUID-d8291f61-aa31-813a-ef16-3f6dec73d643
export const oneTrustMethods = {
  closeAll: 'Close', // closes banner and settings dialog
  openSettingsDialog: 'ToggleInfoDisplay',
  setDataSubjectId: 'setDataSubjectId',
  getGeolocationData: 'getGeolocationData',
  rejectAll: 'RejectAll',
  onConsentChanged: 'OnConsentChanged',
};

// https://app.onetrust.com/cookies/vendors/iabdetails/df62fe3e-b703-429f-a99e-0e156fc4d049
// https://app.onetrust.com/cookies/vendors/list
const vendorIds = { comscore: 77 };

// handlers should be defined with a single, opt-out-oriented Boolean parameter
export const vendorConsentHandlers = {
  [vendorIds.comscore]: comscoreBeacon,
};

export const activeCookieCategoryHandlers = {
  [cookieCategoriesMap.targeting]: [
    processGTagEvents,
    grantFacebookPixelConsent,
  ],
};

// see purposes section here: https://vendor-list.consensu.org/v2/vendor-list.json
export const purposes = {
  1: 'storeAndAccessInformation', // store info e.g., cookies, localStorage
  2: 'basicAds', // show ads
  3: 'personalizedAdsProfile', // create profile for the purpose of personalized ads
  4: 'personalizedAds', // show personalized ads
  5: 'personalizedContentProfile', // collect interests e.g., lotame
  6: 'personalizedContent', // show interests e.g., platform team
  7: 'measureAdPerformance', // report on ad behavior
  8: 'measureContentPerformance', // report on user interaction and feature performance
  9: 'generateAudienceInsights', // generate audience insights e.g., data analytics team
  10: 'developAndImproveProducts', // data be used to improve existing systems and software and to develop new products e.g., data analytics team
};

export const US_PRIVACY_OPT_OUT_STRING = '1YYY';
export const US_PRIVACY_OPT_IN_STRING = '1YNY';
