import { ccpaOptOutCookie, globalOptOutCookie } from '../constants/cookies';
import { getCookie } from '../utils/cookie';

/**
 * this util is meant to be used only to check the value in the cookie
 * in most cases we need to honor global opt out when checking the ccpa opt out
 * if the user is under ccpa. IMPORTANT: Keep in mind that ccpaOptOutCookie was
 * previously being set as false to EVERYONE so its not an indicator that user
 * is under ccpa if that user has the ccpaOptOutCookie.
 * @returns {boolean}
 */
export function isUserOptedOutUnderCcpa() {
  return getCookie(ccpaOptOutCookie.name) === true;
}

/**
 * this util is meant to be used only to check the value in the cookie
 * in most cases we need to honor ccpa opt out when checking the global opt out
 * if the user is under global but was previously under ccpa
 * @returns {boolean}
 */
export function isUserOptedOutUnderGlobal() {
  return getCookie(globalOptOutCookie.name) === true;
}
